<template lang="pug">
	v-container(fluid)
		v-row.mb-2(justify='end')
			.center-block.mr-7
				v-text-field.search-field(
					placeholder='Поиск по названиию',
					height='40',
					v-model='search',
					dense,
					outlined,
					hide-details
				)
				v-btn.ml-2(@click='NewAdv', height='40', color='accent', elevation='0') Новый рекламодатель
		v-data-table.table-container.mt-4(
			:headers='headers',
			:items='advertisers',
			:loading="isLoading",
			loading-text='Данные загружаются, ожидайте',
		)
			template(v-slot:progress)
				v-progress-linear(color="accent" indeterminate)
			template(v-slot:no-data)
				.text-center.no-data_background.pa-10(v-if='!isLoading')
					v-icon.mt-14(size='48', color='#BDBDBD') mdi-clock
					h2.mt-4 Нет данных
					h3.mt-2 Не найден рекламодатель с таким названием
			template(v-slot:header.name='{ header }')
				tooltip(text='Имя аккаунта рекламодателя') {{ header.text }}
			template(v-slot:item.name='{ item }')
				v-btn.mt-3.mb-3.v-btn--table-link.v-btn--table-link--colored.pl-2(
					@click='GoToAccount(item)',
					outlined,
					color='accent'
					elevation='0'
				) {{ item.name }}
			template(v-slot:header.charge='{ header }')
				tooltip(text='Наценка') {{ header.text}}
			template(v-slot:item.charge=' { item }')
				span.header__item {{ formatNumber(item.charge) }}
			template(v-slot:header.balance='{ header }')
				tooltip(text='Баланс рекламодателя') {{ header.text }}
			template(v-slot:item.balance='{ item }')
				span.header__item {{ formatNumber(item.balance) }}
			template(v-slot:header.check='{ header }')
				tooltip(text='Управление счетом') {{ header.text }}
			template(v-slot:item.check='{ item }')
				v-btn-toggle(v-if='!(EditBudgetAdvID === item.id)')
					tooltip(text='Пополнение баланса аккаунта')
						v-btn.mr-1(
							small,
							width='115',
							height='32',
							color='accent',
							elevation='0',
							@click='EditBalance(item.id, "plus")'
						) Пополнить
					tooltip(text='Снять сумму со счета аккаунта')
						v-btn(
							small,
							color='accent',
							height='32',
							width='85'
							elevation='0',
							@click='EditBalance(item.id, "minus")'
						) Снять
				v-row(v-if='EditBudgetAdvID === item.id')
					v-text-field.ml-5.money-field(
						dense,
						outlined,
						hide-details,
						type='number',
						min='0',
						v-model='MoneyCount'
					)
					v-btn.ml-2(
						width='44',
						height='36',
						x-small,
						color='accent'
						@click='ApplyBalance(item)',
						v-bind:class='{ loading: TransferingMoney }'
					)
						v-icon(small) mdi-check
					v-btn.ml-1.mr-5(
						color='accent',
						width='44',
						height='36',
						x-small,
						@click='EditBudgetAdvID = -1'
					)
						v-icon(x-small) mdi-block-helper
			template(v-slot:header.actions)
				export(
					filename='Сводная таблица',
					:headers='["Название", "Ответственный", "Email", "Телефон", "Отрасль", "ИНН", "Наценка", "Баланс", "Счет"]',
					:data='Export()'
				)
			template(v-slot:item.actions='{ item }')
				v-row
					tooltip(text='Скопировать ID рекламодателя')
						v-btn.mr-2(
							width='32',
							height='33',
							color='grey darken-4',
							icon,
							elevation='2',
							@click='clipboardCopy(item.id, item.name)'
						)
							v-icon(small) mdi-content-copy
					tooltip(text='Редактировать данные рекламодателя')
						v-btn.mr-2(
							color='grey darken-4',
							width='32',
							height='33',
							icon,
							elevation='2',
							@click='Adv(item)'
						)
							v-icon(small) mdi-lead-pencil
					tooltip(text='Посмотреть транзакции')
						v-btn(
							color='grey darken-4'
							width='32',
							height='33',
							icon,
							elevation='2',
							@click='transactionDialog = true; currentAccount = item'
						)
							v-icon(small) mdi-menu
		v-dialog(v-model='AdvDialogShow', width='1100')
			v-toolbar(elevation='0')
				v-toolbar-title.pt-5-pb-5
					.dialog-title {{CreateNewAdv ? 'Новый рекламодатель' : 'Редактирование рекламодателя'}}
				v-spacer
				v-toolbar-items
					v-btn.pt-5-pb-5.pl-6(
						elevation='0',
						color='accent',
						plain,
						icon,
						@click='AdvDialogShow = false'
					)
						v-icon(small) mdi-close
			v-tabs(v-model='AdvTabsValue', slider-color='accent', color='#303133')
				v-tab Основное
				v-tab Настройки
				v-tab Дополнительно
			v-tabs-items(v-model='AdvTabsValue')
				v-divider.mr-6.ml-5
				v-tab-item
					v-card-text.mt-4
						v-row
							v-col(cols='4')
								v-subheader Название
									sup.text-error *
							v-col(cols='8')
								v-text-field.mt-1.mr-4(
									outlined,
									height='40'
									dense,
									hide-details,
									v-model='AdvData.name',
									type='text',
									placeholder='Имя рекламодателя',
								)
				v-tab-item
					v-card-subtitle.v-card-subtitle Баланс
					v-card-text
						v-row
							v-col(cols='5')
								v-subheader Уведомлять при снижении баланса ниже
							v-col.mt-1(cols='7')
								v-text-field.mr-4(
									v-model='AdvData.waterlineBalance',
									height='40',
									type='number',
									placeholder='5000',
									outlined,
									dense,
									hide-details
								)
						v-row.mt-4.mb-4
							v-col(cols='5')
								v-subheader Процент наценки
									sup.text-error *
							v-col.mt-1(cols='7')
								v-text-field.mr-4(
									v-model.number='AdvData.charge',
									height='40',
									type='number',
									:min='0',
									:max='1',
									:step='0.01',
									placeholder='Процент наценки',
									outlined,
									dense,
									hide-details
								)
						hr
					v-card-subtitle.v-card-subtitle Автоматическое пополнение
					v-row.ml-0
						v-col(cols='5')
							v-subheader Сумма, на которую надо пополнять аккаунт (в Руб.)
						v-col(cols='7')
							v-text-field.mr-8(
								v-model='AdvData.refillInfo.refillAmount',
								height='40',
								type='number',
								placeholder='5000',
								outlined,
								dense,
								hide-details
							)
					v-row.ml-0
						v-col(cols='5')
							v-subheader Время, как часто надо пополнять балланс
						v-col.mt-4(cols='3')
							v-row(justify='center', align='center')
								v-subheader Дни:
								v-text-field.mr-5(
									v-model='AdvData.refillInfo.days',
									:min='0',
									type='number',
									height='40',
									placeholder='Дни',
									outlined,
									dense,
									hide-details
								)
						v-col.mt-4(cols='4')
							v-row(justify='center', align='center')
								v-subheader Часы:
								v-text-field.mr-11(
									v-model='AdvData.refillInfo.hours',
									type='number',
									height='40',
									min='0',
									max='24',
									placeholder='Часы',
									outlined,
									dense,
									hide-details
								)
					v-row.ml-0
						v-col(cols='5')
							v-subheader Расходы в клиентском доступе
						v-col(cols='7')
							v-switch(
								v-model='AdvData.showbudget',
								hide-details,
								color='indigo darken-3',
								value='indigo darken-3'
							)
				v-tab-item
					v-card-text.mt-4
						v-row
							v-col(cols='4')
								v-subheader Email
							v-col(cols='8')
								v-text-field.mt-1(
									height='40',
									v-model='AdvData.email',
									type='email',
									placeholder='Электронная почта',
									outlined,
									dense,
									hide-details
								)
						v-row
							v-col(cols='4')
								v-subheader Отрасль
							v-col(cols='8')
								v-text-field.mt-1(
									height='40',
									v-model='AdvData.industry',
									type='text',
									placeholder='Отрасль рекламодаталя',
									outlined,
									dense,
									hide-details
								)
						v-row
							v-col(cols='4')
								v-subheader ИНН
							v-col(cols='8')
								v-text-field.mt-1(
									height='40',
									v-model='AdvData.inn',
									type='text',
									placeholder='ИНН',
									v-mask='\'##########\'',
									outlined,
									dense,
									hide-details
								)
						v-row
							v-col(cols='4')
								v-subheader Ответственный
							v-col(cols='8')
								v-text-field.mt-1(
									height='40',
									v-model='AdvData.person',
									type='text',
									placeholder='Ответственный',
									outlined,
									dense,
									hide-details
								)
						v-row
							v-col(cols='4')
								v-subheader Телефон
							v-col(cols='8')
								v-text-field.mt-1(
									height='40',
									v-model='AdvData.phone',
									type='tel',
									placeholder='Телефон ответственного',
									v-mask='\'+# (###) ###-##-##\''
									outlined,
									dense,
									hide-details
								)
				v-card-actions
					v-spacer
					v-btn(
						width='105',
						height='40',
						color='accent',
						v-if='CreateNewAdv',
						@click='CreateAdv()',
						:disabled='AdvData.login == "" || AdvData.password == "" || AdvData.charge == "" || AdvData.name == ""',
						:class='{ loading: isLoading }'
						elevation='0'
					) Создать
					v-btn(
						width='105',
						height='40',
						color='accent',
						v-if='!CreateNewAdv',
						@click='EditAdv()',
						:class='{ loading: isLoading }'
						elevation='0'
					) Изменить
					v-btn(
						width='95',
						height='40',
						@click='AdvDialogShow = false'
						outlined,
						text,
						color='#616161',
						elevation='0'
					) Отмена
		v-dialog(v-model='transactionDialog', width='1100')
			transactions(
				v-if='transactionDialog',
				:title='`Транзакции рекламодателя "${currentAccount.name}"`',
				v-model='currentAccount',
				interfaceName='agency'
			)
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {formatRquestStatsData} from '@/mixins/date_mixin'
import pagination from '@/components/pagination'
import tooltip from '../../components/tooltip'
import ExportTable from '@/components/export_table'
import transactions from '@/components/transactions'
import {NEW_MODE} from '@/store/const/auth'
import loading from '@/mixins/loading_mixin'
import {CREATE_UPDATE_ACCOUNT, GET_ACCOUNT_DATA} from '@/store/const/agency'
import {AGENCY_TRANSFER_MONEY} from '@/store/const/root'

export default {
	name: 'advertisers',
	components: {
		pagination,
		export: ExportTable,
		transactions,
		tooltip,
	},
	mixins: [formatRquestStatsData, loading],
	data: function () {
		return {
			currentAccount: '',
			transactionDialog: false,
			AdvTabsValue: 'first',
			search: '',
			AdvDialogShow: false,
			charge_checked: false,
			CreateNewAdv: false,
			EditBudgetAdvID: -1,
			MoneyCount: 0,
			EditBudgetAction: '',
			TransferingMoney: false,
			CurrentPage: 1,
			headers: [
				{text: 'Название', value: 'name', align: 'left', sortable: false},
				{text: 'Наценка', value: 'charge', align: 'center', sortable: false},
				{text: 'Баланс', value: 'balance', align: 'center', sortable: false},
				{text: 'Счет', value: 'check', align: 'center', sortable: false, width: 250},
				{value: 'actions', align: 'right', sortable: false, width: 130}
			],
			AdvData: {
				name: '',
				refillInfo: {
					days: '',
					hours: '',
				},
			},
		}
	},
	computed: {
		...mapGetters('Agency', ['getAccountData']),
		advertisers() {
      console.log('this.getAccountData', this.getAccountData)
			if (this.search.length !== 0) {
				return this.getAccountData.filter(
					(obj) => obj.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1,
				)
			}
			return this.getAccountData
		},
	},
  async mounted() {
    this.setLoadingActions()
  },
  methods: {
		...mapActions('Auth', [NEW_MODE]),
		...mapActions('Agency', [CREATE_UPDATE_ACCOUNT, GET_ACCOUNT_DATA]),
		...mapActions('Root', [AGENCY_TRANSFER_MONEY]),
		setLoadingActions() {
			this.actions = [
				CREATE_UPDATE_ACCOUNT,
				GET_ACCOUNT_DATA,
				AGENCY_TRANSFER_MONEY
			]
		},
		clipboardCopy(id, name) {
			this.$copyText(id)
			this.$notify({
				text: `ID рекламодателя "${name}" скопирован`,
				type: 'success'
			})
		},
		async GoToAccount(row) {
			let params = {
				id: row.id,
				type: 'account',
				session_type: 'agency',
			}
			await this.NEW_MODE(params)
			if (this.isError(NEW_MODE)) {
				this.$notify({
					type: 'error',
					text: 'Ошибка пре переходе'
				})
				return
			}
			await this.$router.push({path: '/radar/'})
		},
		NewAdv() {
			this.AdvData = {
				name: '',
				refillInfo: {
					days: '',
					hours: '',
				},
			}
			this.CreateNewAdv = true
			this.AdvDialogShow = true
			this.AdvTabsValue = 'first'
		},
		Adv(row) {
			let row_copy = Object.assign({}, row)
			this.CreateNewAdv = false
			this.AdvDialogShow = true
			this.AdvTabsValue = 'first'
			if (row_copy.charge === 0) {
				row_copy.charge = 0.0
			}
			this.AdvData = JSON.parse(JSON.stringify(row_copy))
			const refillTimeout = this.AdvData.refillInfo.refillTimeout
			if (refillTimeout === 0) {
				this.AdvData.refillInfo.hours = 0
				this.AdvData.refillInfo.days = 0
			} else if (refillTimeout >= 86400) {
				let days = refillTimeout
				days = ~~(refillTimeout / 86400)
				this.AdvData.refillInfo.hours = Math.ceil((refillTimeout - 86400 * days) / 3600)
				this.AdvData.refillInfo.days = days
			} else {
				this.AdvData.refillInfo.hours = Math.ceil(refillTimeout / 3600)
				this.AdvData.refillInfo.days = 0
			}
		},
		async CreateAdv() {
			this.AdvData.refillInfo.refillAmount = parseInt(this.AdvData.refillInfo.refillAmount)
			this.AdvData.refillInfo.refillTimeout = 0
			if (this.AdvData.refillInfo.days > 0) {
				this.AdvData.refillInfo.refillTimeout += 3600 * 24 * parseFloat(this.AdvData.refillInfo.days)
			}
			if (this.AdvData.refillInfo.hours > 0) {
				this.AdvData.refillInfo.refillTimeout += 3600 * parseFloat(this.AdvData.refillInfo.hours)
			}
			this.AdvData.refillInfo.refillTimeout = parseFloat(this.AdvData.refillInfo.refillTimeout)
			this.AdvData.charge = parseFloat(this.AdvData.charge)
			this.AdvData.waterlineBalance = Number(this.AdvData.waterlineBalance)
			await this.CREATE_UPDATE_ACCOUNT(this.AdvData)
			if (this.isError(CREATE_UPDATE_ACCOUNT)) {
				this.$notify({
					text: 'Что-то пошло не так',
					type: 'error'
				})
				return
			}
			this.AdvDialogShow = false
			await this.GET_ACCOUNT_DATA()
		},
		async EditAdv() {
			this.AdvData.refillInfo.refillAmount = parseInt(this.AdvData.refillInfo.refillAmount)
			this.AdvData.refillInfo.refillTimeout = 0
			if (this.AdvData.refillInfo.days > 0) {
				this.AdvData.refillInfo.refillTimeout += 3600 * 24 * parseFloat(this.AdvData.refillInfo.days)
			}
			if (this.AdvData.refillInfo.hours > 0) {
				this.AdvData.refillInfo.refillTimeout += 3600 * parseFloat(this.AdvData.refillInfo.hours)
			}
			this.AdvData.refillInfo.refillTimeout = parseFloat(this.AdvData.refillInfo.refillTimeout)
			this.AdvData.charge = parseFloat(this.AdvData.charge)
			this.AdvData.waterlineBalance = Number(this.AdvData.waterlineBalance)
			await this.CREATE_UPDATE_ACCOUNT(this.AdvData)
			if (this.isError(CREATE_UPDATE_ACCOUNT)) {
				this.$notify({
					text: 'Что-то пошло не так',
					type: 'error'
				})
				return
			}
			this.AdvDialogShow = false
			await this.GET_ACCOUNT_DATA()
		},
		EditBalance(index, action) {
			this.EditBudgetAdvID = index
			this.EditBudgetAction = action
		},
		Export() {
			return this.getAccountData.map((row) => {
				return [
					row.name,
					row.person,
					row.email,
					row.phone,
					row.industry,
					row.inn,
					parseFloat(row.charge).toFixed(2),
					parseFloat(row.balance).toFixed(2),
				]
			})
		},
		async ApplyBalance(row) {
			if (!this.TransferingMoney) {
				this.TransferingMoney = true
				let money = this.MoneyCount
				if (this.EditBudgetAction === 'minus') {
					if (row.balance - money < 0) {
						this.$notify({
							type: 'error',
							title: 'Ошибка',
							text: 'Баланс рекламодателя не может быть отрицательным',
						})
						this.TransferingMoney = false
						return
					}
					money = -money
				}
				await this.AGENCY_TRANSFER_MONEY({
					id: row.id,
					money: money,
					session_type: 'agency'
				})
				this.TransferingMoney = false
				this.EditBudgetAdvID = -1
				if (this.isError(AGENCY_TRANSFER_MONEY)) {
					this.$notify({
						type: 'error',
						title: 'Ошибка',
						text: 'Что-то пошло не так',
					})
					return
				}
				this.$notify({
					type: 'success',
					title: 'Успешно',
					text: 'Транзакция завершилась успешно',
				})
				await this.GET_ACCOUNT_DATA()
			}
		},
	},
}
</script>
<style lang="scss">
.search-field {
	width: 185px !important;
	height: 40px !important;
}

.v-data-table th {
	color: #000000DE !important;
	font-size: 16px !important;
}

.header__item {
	font-size: 16px;
}

.v-tab {
	text-transform: none !important;
}

.v-card-subtitle {
	font-size: 16px;
	color: #606266;
}

@media screen and (max-width: 900px) {
	.table-container {
		margin: 0 auto;
		border-collapse: collapse;
		overflow-x: auto;
		display: block;
		width: max-content;
		max-width: 100%;
	}
}
</style>
